@tailwind base;
@tailwind components;
@tailwind utilities;

a,
h2,
h3,
span {
  color: #3c3e42f5;
}
